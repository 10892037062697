import React, { useState } from "react";
import { Container, Button, Dropdown } from "@atoms";
import { Card, Testimonial } from "@molecules";
import convertToOptions from "@utils/convertToOptions";

const CaseStudyIndex = ({ entries, categories }) => {
  const [filter, setFilter] = useState("all");
  const [limit, setLimit] = useState(6);

  const filtered = entries.filter(e => {
    const cat = e?.caseStudyCategory?.map(t => t.slug) || [];
    const show = cat.includes(filter) || filter === "all";
    return show;
  });

  return (
    <Container>
      <div className="my-10 hidden flex-wrap justify-center gap-6 gap-y-3 sm:flex">
        {categories.map(cat => (
          <Button
            onClick={() => setFilter(cat.slug)}
            color={cat.slug !== filter ? "white" : "purple"}
            outline
            size="sm"
          >
            {cat.title}
          </Button>
        ))}
      </div>

      <div className="my-10 block sm:hidden">
        <Dropdown
          model={[filter, setFilter]}
          options={categories.map(convertToOptions)}
        />
      </div>

      <div className="my-10 columns-2xs">
        {filtered?.slice(0, limit)?.map(t => {
          const { type, uid } = t;

          if (type === "testimonials") {
            return (
              <div className="mb-8 break-inside-avoid">
                <Testimonial key={uid} {...t} type="card" headshot="card" />
              </div>
            );
          }
          return (
            <div className="mb-8 break-inside-avoid">
              <Card key={uid} {...t} color="purple" />
            </div>
          );
        })}
      </div>

      {limit !== Infinity && (
        <div className="mt-12 w-full text-center sm:mt-20">
          <Button color="purple" size="sm" onClick={() => setLimit(Infinity)}>
            View All
          </Button>
        </div>
      )}
    </Container>
  );
};

export default CaseStudyIndex;
